import React, { memo, useEffect, useState } from 'react'
import { GrrenTitle, SectionContent, SectionWrapper, SectionTitle, SectionDescription } from './MainHostingSection.styled'
import { Col, Container, Row } from 'react-grid-system';
import MemoCommonButton from '../Common/CommonButton/CommonButton';
import Bg from "../../images/bg.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

type Data = {
  title: string;
  subTitle: string;
  description: string;
  url: string
}

type Props = {
  image?: string,
  data?: Data[];
}

const MainHostingSection = ({image, data}: Props) => {
  const { i18n } = useTranslation();
  // const {direction, setDirection} = useState(i18n.language)
  useEffect(() => {

  }, [i18n.language])
  return (
    <SectionWrapper>
      <img src={image} alt={data && data[0].subTitle} />
      <SectionContent>
        <Container fluid>
          <Row>
            <Col xs={12} >
              <Swiper
                dir={i18n.language === "ar" ? 'rtl' : 'ltr'}
                modules={[ Pagination]}
                slidesPerView={1}
                pagination={{ clickable: true }}
                
              >
                {data?.map((item, index) => (
                  <SwiperSlide key={index} >
                    <GrrenTitle>{item.title}</GrrenTitle>
                    <SectionTitle>{item.subTitle}</SectionTitle>
                    <SectionDescription>
                      {item.description}
                    </SectionDescription>
                    <MemoCommonButton name='Start Now' variant='filled' />
                  </SwiperSlide>
                ))}
                
              </Swiper>
            </Col>
          </Row>
        </Container>
      </SectionContent>
    </SectionWrapper>
  )
}


const MemoMainHostingSection = memo(MainHostingSection)
export default MemoMainHostingSection;