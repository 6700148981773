import { memo } from 'react'
import { Helmet } from 'react-helmet';
import Bg from "../../images/bg.png";
import MemoMainHostingSection from '../../components/MainHostingSection/MainHostingSection';
import FeaturesTabs from '../../components/FeaturesTabs/FeaturesTabs';
import MemoNestedTabs from '../../components/NestedTabs/NestedTabs';
import { useTranslation } from 'react-i18next';
type Props = {}

function EuropeHosting({}: Props) {
  const { t } = useTranslation();
  const mainSectionData = [
    {
      title: "Launch your site with a Saudi identity with",
      subTitle: "saudi Servers",
      description: "Establish your site now with Saudi Shared Hosting with the first host in the Arab world .",
      url: "/"
    },
    {
      title: "Launch your site with a Saudi identity with",
      subTitle: "saudi Servers",
      description: "Establish your site now with Saudi Shared Hosting with the first host in the Arab world .",
      url: "/"
    }
  ]
  const featuresMainTabs = [t('common.linux'), t('common.windows')];
  const tabs = ['tab1', 'tab2', 'tab3', 'tab4', 'tab5']

  const featuresDataa = {
    title: '',
    description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
  }

  const featuresLinuxTabs = [
    t('saudi-hosting.linux.tab1.title'),
    t('saudi-hosting.linux.tab2.title'),
    t('saudi-hosting.linux.tab3.title'),
    t('saudi-hosting.linux.tab4.title'),
  ]
  const featuresWindowsTabs = [
    t('saudi-hosting.windows.tab1.title'),
    t('saudi-hosting.windows.tab2.title'),
    t('saudi-hosting.windows.tab3.title'),
    t('saudi-hosting.windows.tab4.title'),
  ]

  const featureList = [
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.meta.description'),
              description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
            },
          ]
        }
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      }
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.meta.description'),
              description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
            },
          ]
        }
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      },
      {
        panel: {
          features: [
            featuresDataa,
            featuresDataa,
            featuresDataa,
            featuresDataa,
          ]
        },
      }
    ]
  ]
  return (
    <>
      <MemoMainHostingSection 
        image={Bg}
        data={mainSectionData}
      />
      <MemoNestedTabs 
        title='Our Pricing Plan' 
        subTitle='Saudi Hosting Plans'
        golden
        mainTabs={['Single-CPU', 'DUAL-CPU']}
        nestedTabs={['Free Management', 'Advanced Management']}
      /> 
      <FeaturesTabs
        title= 'View Our'
        subTitle= 'Dedicated Hosting Features'
        description= 'We always aim at providing the best web hosting at the least prices as Dimofinf has its own servers which .'
        mainTabList={featuresMainTabs}
        windowsTabList={featuresWindowsTabs}
        linuxTabList={featuresLinuxTabs}
        tabsList={tabs}
        data={featureList}
      />
    </>
  )
}

const MemoEuropeHosting = memo(EuropeHosting)

export default MemoEuropeHosting;