import { Route, Routes } from "react-router-dom"
import MemoBaseLayout from "../Layouts/BaseLayout/BaseLayout"
import MemoAbout from "../Pages/About/About"
import { lazy } from 'react'
import MemoLayout from "../Layouts/Layout/Layout"
import MemoSaudiHosting from "../Pages/SaudiHosting/SaudiHosting"
import MemoDedicatedHosting from "../Pages/DedicatedHosting/DedicatedHosting"
import MemoCloudHosting from "../Pages/CloudHosting/CloudHosting"
import MemoEuropeHosting from "../Pages/EuropeHosting/EuropeHosting"

const LazyHome = lazy(() => import('../Pages/Home/Home'))

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<MemoBaseLayout/>} >
        <Route path="/" element={<LazyHome />} />
        <Route path="/about" element={<MemoAbout />} />
      </Route>
      <Route element={<MemoLayout/>}>
        <Route path="/saudi-hosting" element={<MemoSaudiHosting />} />
        <Route path="/dedicated-hosting" element={<MemoDedicatedHosting />} />
        <Route path="/cloud-hosting" element={<MemoCloudHosting />} />
        <Route path="/europe-hosting" element={<MemoEuropeHosting />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes;