import React, { memo } from 'react'
import MemoCollapse from '../Common/Collapse/Collapse';
import { Col, Container, Row } from 'react-grid-system'
import MemoAnimatedDots from '../Common/AnimatedDots.tsx';
import { SectionContent, SectionData, SectionShape, SectionWrapper } from './CollapseSection.styled';
import { GrrenTitle, HeaderDescription, HeaderTitle} from '../../components/HomePage/SpecialSection/SpecialSection.styled';
import Bg from "../../images/qmark.svg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MemoCommonButton from '../Common/CommonButton/CommonButton';
type Props = {
  title?: string;
  subTitle?: string;
  tabsList: string[];
}

const CollapseSection = ({title, subTitle, tabsList}: Props) => {
  return (
    <SectionWrapper>
      <SectionShape src={Bg} alt='mark' />
      <SectionContent>
        <Container fluid>
          <Row>
            <Col lg={2} md={12} xs={12}>
              <MemoAnimatedDots full position='relative' variant='left' />
            </Col>
            <Col xl={7} lg={8} md={12} xs={12} >
              <SectionData>
                <GrrenTitle>{title} </GrrenTitle>
                <HeaderTitle>{subTitle} </HeaderTitle>
                <Tabs className='main_tabs' >
                  <TabList>
                    {tabsList.map((tab, index) => (
                      <Tab key={index}>
                        <MemoCommonButton name={tab} variant='rounded' />
                      </Tab> 
                    ))}
                  </TabList>
                  <TabPanel>
                    <MemoCollapse path='saudi-hosting.questions.linux' />
                  </TabPanel>
                  <TabPanel>
                    <MemoCollapse path='saudi-hosting.questions.windows' />
                  </TabPanel>
                </Tabs>
              </SectionData>
            </Col>
          </Row>
        </Container>
      </SectionContent>
    </SectionWrapper>
  )
}

const MemoCollapseSection = memo(CollapseSection);

export default MemoCollapseSection;