import styled, { css } from "styled-components";
import { PlanPrice } from "../MainPlanCard/MainPlanCard.styled";
import { Link } from "react-router-dom";

export const Card = styled.div<{
  readonly fav?: boolean;
}>`
  padding: 30px 14px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 12px 12px 19px 0 #00000005;
  position: relative;
  margin-bottom: 20px;
  ${({fav}) => fav && css`
    border: 1px solid #48988D;
  `}
`;

export const Badge = styled.span`
  position: absolute;
  top: 0;
  right: 34px;
  background-color: #F36C42;
  border-bottom-left-radius: 4px;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  width: 26px;
  height: 26px;
  color: #fff;
  text-align: center;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
`;

export const Icon = styled.img`
  width: 18px;
  height: 12px;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  color: #464545;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 43px;
  color: #475973;
  span {
    font-weight: 700;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled(PlanPrice)`
  margin: 0;
`;

export const OrderBtn = styled(Link)`
  padding: 6px 15px;
  /* width: 84px; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-bottom-left-radius: 4px;
  border: 1px solid #48988D;
  white-space: nowrap;
  background-color: #fff;
  color: #3B3B3B;
  text-decoration: none;
  display: inline-block;
`;