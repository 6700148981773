import React, { memo } from 'react'
import MemoMainHostingSection from '../../components/MainHostingSection/MainHostingSection'
import Bg from "../../images/bg.png";
import CloudIcon from "../../images/icons/cloud.svg"
import MemoMiniPlansSection from '../../components/MiniPlansSection/MiniPlansSection';
import MemoFeaturesSection from '../../components/FeaturesSection/FeaturesSection';
type Props = {}



const CloudHosting = ({}: Props) => {

  const data = {
    greentitle: 'Our Pricing Plan',
    title: 'Cloud Server Plans  ',
    description: 'Subscribe to Dimofinf Cloud servers now and enjoy the latest cloud hosting technologies available in the market, in addition to, the best performance, highest uptime',
    cards: [
      {
        id: 1,
        padge: 'X1',
        icon: CloudIcon,
        title: 'Cloud server',
        description: 'CPU 3 core RAM 4 GB DDR4 Traffic  20 TB Storage 80 GB SSD',
        price: 60,
        url: ''
      },
      {
        id: 2,
        padge: 'X2',
        icon: CloudIcon,
        title: 'Cloud server',
        description: 'CPU 3 core RAM 4 GB DDR4 Traffic  20 TB Storage 80 GB SSD',
        price: 50,
        url: '',
        fav: true
      },
      {
        id: 3,
        padge: 'X3',
        icon: CloudIcon,
        title: 'Cloud server',
        description: 'CPU 3 core RAM 4 GB DDR4 Traffic  20 TB Storage 80 GB SSD',
        price: 80,
        url: ''
      },
      {
        id: 4,
        padge: 'X4',
        icon: CloudIcon,
        title: 'Cloud server',
        description: 'CPU 3 core RAM 4 GB DDR4 Traffic  20 TB Storage 80 GB SSD',
        price: 30,
        url: ''
      },
      {
        id: 5,
        padge: 'X5',
        icon: CloudIcon,
        title: 'Cloud server',
        description: 'CPU 3 core RAM 4 GB DDR4 Traffic  20 TB Storage 80 GB SSD',
        price: 90,
        url: ''
      },
      {
        id: 6,
        padge: 'X6',
        icon: CloudIcon,
        title: 'Cloud server',
        description: 'CPU 3 core RAM 4 GB DDR4 Traffic  20 TB Storage 80 GB SSD',
        price: 40,
        url: ''
      },
    ]
  }

  const featuresData = {
    title: 'View Our',
    subTitle: 'Dedicated Hosting Features',
    description: 'We always aim at providing the best web hosting at the least prices as Dimofinf has its own servers which .',
    action:'',
    cards: [
      {
        id: 1,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 2,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 3,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 4,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 5,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 6,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 7,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 8,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 9,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
    ]
  }

  const mainSectionData = [
    {
      title: "Launch your site with a Saudi identity with",
      subTitle: "saudi Servers",
      description: "Establish your site now with Saudi Shared Hosting with the first host in the Arab world .",
      url: "/"
    },
    {
      title: "Launch your site with a Saudi identity with",
      subTitle: "saudi Servers",
      description: "Establish your site now with Saudi Shared Hosting with the first host in the Arab world .",
      url: "/"
    }
  ]

  return (
    <>
      <MemoMainHostingSection 
        image={Bg}
        data={mainSectionData}
      />
      <MemoMiniPlansSection data={data} />
      <MemoFeaturesSection 
        data={featuresData}
      /> 
    </>
  )
}

const MemoCloudHosting = memo(CloudHosting)

export default MemoCloudHosting