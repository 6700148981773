import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 26px 60px;
  box-shadow: 12px 12px 19px 0 #00000005;
  border-radius: 20px;
  transform: translateY(60px);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const P = styled.p`
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  color: #000;
  max-width: 512px;
`;