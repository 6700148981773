// ts-nocheck
import { memo } from 'react'
import CloudIcon from "../../images/icons/cloud.svg"
import { Card, Badge, CardHeader, Icon, Title, Description, CardFooter, Price, OrderBtn } from './MiniPlanCard.styled';
import Link from 'react-router-dom';
type Props = {
  padge?: string;
  title?: string;
  description?: string;
  price?: number | string;
  actionLink?: string;
  fav?: boolean;
  icon: string;
}

const MiniPlanCard = ({padge, title, description, price, actionLink, fav, icon}: Props) => {
  return (
    <Card fav={fav}>
      <Badge>{padge}</Badge>
      <CardHeader>
        <Icon src={icon} alt='Cloud Icon' />
        <Title>{title}</Title>
      </CardHeader>
      <Description>{description}</Description>
      <CardFooter>
        <Price>{price}$ /month</Price>
        <OrderBtn to={`/${actionLink}`} >Order Now</OrderBtn>
      </CardFooter>
    </Card>
  )
}

const MemoMiniPlanCard = memo(MiniPlanCard)

export default MemoMiniPlanCard;