import { memo } from 'react'
import { P, Wrapper } from './CloudHostingComparision.styled'
import MemoCommonButton from '../../Common/CommonButton/CommonButton'

type Props = {}

const CloudHostingComparision = (props: Props) => {
  return (
    <Wrapper>
      <P>Cloud server management levels Comparison</P>
      <MemoCommonButton name='View' variant='filled' />
    </Wrapper>
  )
}

const MemoCloudHostingComparision = memo(CloudHostingComparision)

export default MemoCloudHostingComparision